export const CONNECTION_GAMES = [
    {
        "int": [
            {
                "words": [
                    "Corona",
                    "Jimenez",
                    "Alvarez",
                    "Lozano"
                ],
                "wordsImages": [
                    {
                        "title": "Corona",
                        "image": "https:\/\/kickoff11.com\/media\/players\/corona.png"
                    },
                    {
                        "title": "Jimenez",
                        "image": "https:\/\/kickoff11.com\/media\/players\/jimenez.webp"
                    },
                    {
                        "title": "Alvarez",
                        "image": "https:\/\/kickoff11.com\/media\/players\/alvarez.webp"
                    },
                    {
                        "title": "Lozano",
                        "image": "https:\/\/kickoff11.com\/media\/players\/p221992.png"
                    }
                ],
                "category": "From Mexico",
                "difficulty": 1,
                "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                "setId": "7fbb373c900f90aa4ab887da6eaaa9a240979d00"
            },
            {
                "words": [
                    "Silva",
                    "Nunes",
                    "Felix",
                    "Semedo"
                ],
                "wordsImages": [
                    {
                        "title": "Silva",
                        "image": "https:\/\/kickoff11.com\/media\/players\/bernardosilva.webp"
                    },
                    {
                        "title": "Nunes",
                        "image": "https:\/\/kickoff11.com\/media\/players\/nunes.webp"
                    },
                    {
                        "title": "Felix",
                        "image": "https:\/\/kickoff11.com\/media\/players\/p242444.png"
                    },
                    {
                        "title": "Semedo",
                        "image": "https:\/\/kickoff11.com\/media\/players\/semedo.png"
                    }
                ],
                "category": "From Portugal",
                "difficulty": 1,
                "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                "setId": "7fbb373c900f90aa4ab887da6eaaa9a240979d00"
            },
            {
                "words": [
                    "Pulisic",
                    "Dempsey",
                    "Friedel",
                    "McKennie"
                ],
                "wordsImages": [
                    {
                        "title": "Pulisic",
                        "image": "https:\/\/kickoff11.com\/media\/players\/pulisic.webp"
                    },
                    {
                        "title": "Dempsey",
                        "image": "https:\/\/kickoff11.com\/media\/players\/dempsey.png"
                    },
                    {
                        "title": "Friedel",
                        "image": "https:\/\/kickoff11.com\/media\/players\/friedel.png"
                    },
                    {
                        "title": "McKennie",
                        "image": "https:\/\/kickoff11.com\/media\/players\/mckennie.webp"
                    }
                ],
                "category": "From United States",
                "difficulty": 1,
                "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                "setId": "7fbb373c900f90aa4ab887da6eaaa9a240979d00"
            },
            {
                "words": [
                    "Keane",
                    "O'Shea",
                    "R.Keane",
                    "Irwin"
                ],
                "wordsImages": [
                    {
                        "title": "Keane",
                        "image": "https:\/\/kickoff11.com\/media\/players\/robbie-keane.png"
                    },
                    {
                        "title": "O'Shea",
                        "image": "https:\/\/kickoff11.com\/media\/players\/oshea.png"
                    },
                    {
                        "title": "R.Keane",
                        "image": "https:\/\/kickoff11.com\/media\/players\/keane.webp"
                    },
                    {
                        "title": "Irwin",
                        "image": "https:\/\/kickoff11.com\/media\/players\/irwin.png"
                    }
                ],
                "category": "From Ireland",
                "difficulty": 1,
                "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                "setId": "7fbb373c900f90aa4ab887da6eaaa9a240979d00"
            }
        ],
        "de": [
            {
                "words": [
                    "Widmer",
                    "da Costa",
                    "Trimmel",
                    "Gamboa"
                ],
                "wordsImages": [
                    {
                        "title": "Widmer",
                        "image": "https:\/\/kickoff11.com\/media\/players\/168989-1629202510.png"
                    },
                    {
                        "title": "da Costa",
                        "image": "https:\/\/kickoff11.com\/media\/players\/85906-1657707067.png"
                    },
                    {
                        "title": "Trimmel",
                        "image": "https:\/\/kickoff11.com\/media\/players\/75921-1692707564.png"
                    },
                    {
                        "title": "Gamboa",
                        "image": "https:\/\/kickoff11.com\/media\/players\/121686-1657283791.png"
                    }
                ],
                "category": "Plays as Defender - Right-Back",
                "difficulty": 1,
                "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                "setId": "b9d262ea9a00d92fe3d918b727c0ea47574ba8b7"
            },
            {
                "words": [
                    "Vermeeren",
                    "Bitshiabu",
                    "Nusa",
                    "Gulácsi"
                ],
                "wordsImages": [
                    {
                        "title": "Vermeeren",
                        "image": "https:\/\/kickoff11.com\/media\/players\/926694-1729520583.png"
                    },
                    {
                        "title": "Bitshiabu",
                        "image": "https:\/\/kickoff11.com\/media\/players\/787912-1672304780.png"
                    },
                    {
                        "title": "Nusa",
                        "image": "https:\/\/kickoff11.com\/media\/players\/894914-1724337096.png"
                    },
                    {
                        "title": "Gulácsi",
                        "image": "https:\/\/kickoff11.com\/media\/players\/57071-1719214600.png"
                    }
                ],
                "category": "Plays for RB Leipzig",
                "difficulty": 1,
                "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                "setId": "b9d262ea9a00d92fe3d918b727c0ea47574ba8b7"
            },
            {
                "words": [
                    "Diehl",
                    "Bülter",
                    "Ibrahimovic",
                    "Bahoya"
                ],
                "wordsImages": [
                    {
                        "title": "Diehl",
                        "image": "https:\/\/kickoff11.com\/media\/players\/720162-1723707320.png"
                    },
                    {
                        "title": "Bülter",
                        "image": "https:\/\/kickoff11.com\/media\/players\/116088-1691269482.png"
                    },
                    {
                        "title": "Ibrahimovic",
                        "image": "https:\/\/kickoff11.com\/media\/players\/744728-1662650250.png"
                    },
                    {
                        "title": "Bahoya",
                        "image": "https:\/\/kickoff11.com\/media\/players\/939964-1724772389.png"
                    }
                ],
                "category": "Plays as Attack - Left Winger",
                "difficulty": 1,
                "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                "setId": "b9d262ea9a00d92fe3d918b727c0ea47574ba8b7"
            },
            {
                "words": [
                    "Günter",
                    "Roussillon",
                    "Kabar",
                    "Aznou"
                ],
                "wordsImages": [
                    {
                        "title": "Günter",
                        "image": "https:\/\/kickoff11.com\/media\/players\/93707-1658411784.png"
                    },
                    {
                        "title": "Roussillon",
                        "image": "https:\/\/kickoff11.com\/media\/players\/127076-1691614715.png"
                    },
                    {
                        "title": "Kabar",
                        "image": "https:\/\/kickoff11.com\/media\/players\/884820-1713462323.png"
                    },
                    {
                        "title": "Aznou",
                        "image": "https:\/\/kickoff11.com\/media\/players\/938146-1692946563.png"
                    }
                ],
                "category": "Plays as Defender - Left-Back",
                "difficulty": 1,
                "imageSrc": "https:\/\/i.ibb.co\/Gn36Ypb\/Connections-1.png",
                "setId": "b9d262ea9a00d92fe3d918b727c0ea47574ba8b7"
            }
        ]
    }
]