export const CONNECTION_GAMES_META = [
    {
        "int": {
            "gameIndex": 20073,
            "nextGameAt": 1734303600,
            "baseUrl": "https:\/\/kickoff11.com"
        },
        "de": {
            "gameIndex": 20073,
            "nextGameAt": 1734303600,
            "baseUrl": "https:\/\/kickoff11.com"
        }
    }
]